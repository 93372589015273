.Nav {
  &__container {
    pointer-events: all;
    width: 100%;

    .--animating & {
      pointer-events: none;
    }

    @include responsive($menuBreakpoint, max) {
      @include flex(center, center);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100dvh;
      background: var(--yellow);
      overflow-y: auto;
    }

    body:not(.showMenu) & {
      @include responsive($menuBreakpoint, max) {
        display: none;
      }
    }

    > .inner {
      padding: 100px 40px;

      @include responsive($menuBreakpoint) {
        @include flex(flex-start, space-between);
        padding: 0;
      }

      .--scrolled & {
        align-items: center;
      }
    }

    .cta {
      @include flex(center, center);
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 30px;

      @include responsive($menuBreakpoint) {
        flex-wrap: nowrap;
        margin-top: 0;
      }

      > * {
        @include responsive($menuBreakpoint, max) {
          min-width: 100%;
        }
      }

      body:not(.--scrolled):not(.showMenu) .Header.--w & {
        .Btn.--transparent {
          border-color: var(--white)!important;
          .Btn__txt { color: var(--white); }
        }

        .Btn.--dark {
          background: var(--white);
          border-color: var(--white)!important;
          .Btn__txt { color: var(--base); }
        }
      }
    }
  }

  &__main {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;

    @include responsive($menuBreakpoint, max) {
      @include font-vw(13);
      width: 100%;
    }
  }

  &__item {
    text-align: center;

    @include responsive($menuBreakpoint) {
      display: inline-block;
    }

    & + & {
      @include responsive($menuBreakpoint) {
        margin-left: 2em;
      }
    }
  }

  &__link {
    position: relative;
    transition: filter 400ms $easeOutQuad;

    @include responsive($menuBreakpoint, max){
      font-size: 32px;
    }

    body:not(.--scrolled):not(.showMenu) .Header.--w & {
      color: var(--white);
    }

    &.--active,
    &:focus,
    &:hover {
      filter: opacity(1);

      &:after {
        transform: scaleX(1);
      }
    }

    &.--active {
      cursor: not-allowed;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 90%;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--base);
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 400ms $easeOutQuad;

      @include responsiveMinMax($tabletSmall, $deskXL) {
        height: get-vw(2);
      }

      html.--dark & {
        background: var(--white);
      }

      body:not(.--scrolled):not(.showMenu) .Header.--w & {
        background: var(--white);
      }
    }
  }
}
