.CharteEthiqueConvictions{
  position: relative;

  @include responsive($tabletSmall){
    height: 100vh;
  }

  .container {
    height: 100%;

    @include responsive($tabletSmall){
      @include flex(center, center);
    }
  }

  .container > h2{
    font: 900 8vw / 1 var(--main-font);
    text-align: center;

    @include responsive($tabletSmall){
      @include font-vw(100);
    }
  }

  .container > .items{
    .item{
      background-color: var(--grey-l);
      width: 100%;
      max-width: 500px;
      padding: 3em;
      
      &:nth-child(even){
        background-color: var(--yellow);

        @include responsive($tabletSmall){
          transform: translate(-20%, -50%);
        }
      }

      & + .item{
        @include responsive($tabletSmall, max){
          margin-top: 1em;
        }
      }

      @include responsive($tabletSmall){
        padding: 6em;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-70%, -50%);
        max-width: get-vw(700);
      }

      // Title
      > span{
        font: 900 6vw / 1 var(--main-font);
        text-transform: uppercase;
        margin-bottom: 0.7em;
        display: block;

        @include responsive($tabletSmall){
          @include font-vw(45);
        }
      }

      // Content
      > .wswyg--content{
        *{
          color: var(--grey-d);

          &:not(strong){
            font-weight: 500;
          }
        }
      }
    }
  }
}
