.Candidater {
  &__page {
    .Page__header {
      background: var(--yellow);
      text-align: center;
      padding-bottom: 60px;

      @include responsive($tabletSmall) {
        padding-bottom: get-vw(80);
      }

      h1 {
        display: block;

        span {
          text-align: center;
        }
      }

      address {

        p {
          position: relative!important;
          margin: 0 auto!important;
          bottom: 0!important;
          right: 0!important;
          max-width: 940px;

          @include responsive($tabletSmall) {
            max-width: get-vw(940);
          }

          @include responsive($tabletSmall, max) {
            font-size: 12px;
          }
        }

        a { 
          @extend p;
          margin: 0;
        }
      }

      .Socials {
        margin: 30px auto 0;
        width: 100%;
        justify-content: center;

        @include responsive($tabletSmall) {
          margin-top: get-vw(30);
        }
      }
    }
  }
}