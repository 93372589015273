.CharteEthiqueNavigation{
  .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }
  }

  .container > h2{
    font: 900 6vw / 1 var(--main-font);

    @include responsive($tabletSmall){
      @include font-vw(40);
      position: sticky;
      top: 2em;
      max-width: get-vw(460);
      margin-bottom: 0;
    }
  }

  .container > nav{
    @include responsive($tabletSmall){
      max-width: get-vw(610);
      margin-left: auto;
    }

    ul {
      li {
        border-bottom: 1px solid rgba(#000, 0.12);

        & + li{
          margin-top: 2em;
        }
        
        a {
          @include flex(center);
          gap: 1em;
          padding: 1em 0;

          @include noTouchHover() {
            > .Icon.--tick{
              svg{
                transform: scale(0.8);
              }

              &:after{
                transform: translate(-50%, -50%) scale(1);
              }
            }

            > .square{
              transform: scale(0.8);

              .arrow{
                transform: rotate(90deg);
              }
            }
          }

          > .Icon.--tick{
            &:after,
            svg{
              transition: transform 500ms $ease;
            }

            &:after{
              @include content();
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%, -50%) scale(0);
              border-radius: 50%;
              z-index: -1;
              border-radius: 50%;
              background-color: var(--black);
              height: 25px;
              width: 25px;

              @include responsive($tabletSmall){
                height: get-vw(25);
                width: get-vw(25);
              }
            }
          }
          
          > span{
            font-weight: 700;
            text-transform: uppercase;
            padding-right: 1em;
            flex: 1;

            br{
              display: none;
            }
          }

          > .square{
            @include flex(center, center);
            background-color: var(--yellow);
            height: 50px;
            width: 50px;
            transition: transform 500ms $ease;

            @include responsive($tabletSmall){
              height: get-vw(60);
              width: get-vw(80);
            }

            .arrow{
              transition: transform 500ms $ease;
            }
          }
        }
      }
    }
  }
}
