.TextSimple {

    h2 {
        font-size: 14vw;
        margin-bottom: 60px;

        @include responsive($tabletSmall) {
            @include font-vw(160);
            margin-bottom: get-vw(60);
        }

        &.--splited {
            .--oh {
                &:nth-child(2) {
                    @include responsive($tabletSmall) {
                        span {
                            left: 12%;
                        }
                    }
                }
                &:nth-child(4) {
                    @include responsive($tabletSmall) {
                        span {
                            left: 6%;
                        }
                    }
                }
            }
        }
    }

    .content {
        @include flex(flex-start, space-between);
        flex-wrap: wrap;
        gap: 40px;

        @include responsive($tabletSmall) {
            gap: space(3);
        }

        .Ecole & {
            @include responsive($tabletSmall) {
                gap: space(2);
            }
        }

        > * {
            width: 100%;
        }
    }

    h3 {
        font-size: 38px;
        line-height: 1.1;
        color: var(--base);

        @include responsive($tabletSmall) {
            @include font-vw(38);
            max-width: space(8);
        }
    }

    .wswyg--content {
        @include responsive($tabletSmall) {
            max-width: space(9);
        }

        .Ecole & {
            @include responsive($tabletSmall) {
                max-width: space(11);
            }
        }
    }
}