:root {
  // Colors
  --white: #ffffff;
  --grey: #999999;
  --grey-l: #F2F2F2;
  --grey-l2: #d7d7d7;
  --gold: #af904d;
  --base: #1a1a1a;
  --base-d: #141414;
  --links-color: var(--grey-d);
  --error-color: #dc3838;
  --valid-color: #5da854;

  --black: #0C0C0C;
  --yellow: #FFDA15;
  --grey-d: #282828;
  --text: #696969;
  --text-grey: #d6d6d6;
  --brun: #695F28;

  // Fonts
  --font-color: var(--base);
  --font-color-grey : var(--text-grey);
  --font-color-brun : var(--brun);
  --main-font: 'Maison Neue', sans-serif;

  // Easings
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}


$black: #0C0C0C;
$white: #ffffff;


/* -----
Paths
----- */
$base-images: "../images/";
$base-fonts: "../fonts/";

/* -----
Grid
----- */
$base-vw: 1440;
$base-grid: 24;
$browser-context: 20;

/* -----
CSS Easings
----- */
// $transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
// $snap: cubic-bezier(0,1,.5,1);
// $easeOutCubic: cubic-bezier(.215,.61,.355,1);
// $easeInOutCubic: cubic-bezier(.645,.045,.355,1);
// $easeInCirc: cubic-bezier(.6,.04,.98,.335);
// $easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
// $easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
// $easeInQuart: cubic-bezier(.895,.03,.685,.22);
// $easeOutQuart: cubic-bezier(.165,.84,.44,1);
// $easeInOutQuart: cubic-bezier(.77,0,.175,1);
// $easeInQuint: cubic-bezier(.755,.05,.855,.06);
// $easeOutQuint: cubic-bezier(.23,1,.32,1);
// $easeInOutQuint: cubic-bezier(.86,0,.07,1);
// $easeInSine: cubic-bezier(.47,0,.745,.715);
// $easeOutSine: cubic-bezier(.39,.575,.565,1);
// $easeInOutSine: cubic-bezier(.445,.05,.55,.95);
// $easeInBack: cubic-bezier(.6,-.28,.735,.045);
// $easeOutBack: cubic-bezier(.175, .885,.32,1.275);
// $easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;
