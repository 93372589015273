.Events {

    h2 {
        font-size: 10vw;
        margin-bottom: 30px;
        text-align: right;

        @include responsive($tabletSmall) {
          @include font-vw(158);
          margin-bottom: get-vw(140);
        }
    }

    &__list {
        @include responsive($tabletSmall) {
            padding: 0 space(1);
        }
    }
}

.Event {
    &__preview {
        max-width: 460px;
        margin: 0 auto;

        @include responsive($tabletSmall) {
            @include flex(center, space-between);
            max-width: none;
        }

        &:not(:last-child) {
            margin-bottom: 60px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(100);
            }
        }

        &:nth-child(even) {
            .visual__container {
                order: 2;
            }
        }
        
        > * {
            @include responsive($tabletSmall) {
                max-width: space(9);
            }
        }

        .visual__container {
            @include imgRatio(580, 460, var(--base));
            margin-bottom: 20px;

            @include responsive($tabletSmall) {
                max-width: space(9.5);
                margin-bottom: 0;
            }
        }

        .date {
            display: block;
            color: var(--text);
            font-size: 16px;
            line-height: 1;
            font-weight: 900;
            margin-bottom: 10px;

            @include responsive($tabletSmall) {
                @include font-vw(24);
                margin-bottom: get-vw(10);
            }
        }

        h3 {
            font-size: 24px;
            margin-bottom: 20px;

            @include responsive($tabletSmall) {
                @include font-vw(38);
                margin-bottom: get-vw(30);
            }
        }

        .Btn {
            margin-top: 30px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(30);
            }
        }

    }
}