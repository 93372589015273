.Scrolling__titles {
  position: relative;
  background: var(--black);
  padding: 0;

  .container {
    position: relative;
    z-index: 10;
    padding: 80vh space(1) space(4);

    &:before {
      @include content();
      @include cover();
      z-index: -1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 1) 80%);
    }
  }

  .titles {
    color: var(--white);

    h3 {
      font-size: 12vw;
      text-align: center;

      @include responsive($tabletSmall){
          @include font-vw(100);
      }

      &:nth-child(1) {
        .--oh {
            position: relative;
    
            &:nth-child(2) span {
                @include responsive($tabletSmall) {
                    left: 10%
                }
            }

            &:nth-child(3) span {
                @include responsive($tabletSmall) {
                    left: 27%;
                }
            }
        }
      }

      &:nth-child(2) {
        .--oh {
            position: relative;
    
            &:nth-child(2) span {
                @include responsive($tabletSmall) {
                    left: 5%
                }
            }

            &:nth-child(3) span {
                @include responsive($tabletSmall) {
                    left: -15%;
                }
            }
        }
      }

      &:not(:last-child) {
        margin-bottom: 30vh;
      }
    }
  }

  .text {
    text-align: center;
    
    .Btn {
      margin: 60px auto; 

      @include responsive($tabletSmall) {
        margin: get-vw(80) auto; 
      }
    }

    .wswyg--content {
      margin: 0 auto;
      text-align: center;

      @include responsive($tabletSmall) {
        max-width: get-vw(900);
      }

      p {
        color: var(--white);
        font-size: 20px;
        font-weight: 500;
        line-height: 130%; 
  
        @include responsive($tabletSmall) {
          @include font-vw(24);
        }
      }
    }
  }

  &__bg {
    @include cover();
    z-index: 0;

    .visual__container {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
  }
}