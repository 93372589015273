.Faq {
    background: var(--grey-l);
    
    h2 {
        font-size: 12vw;
        margin-bottom: 30px;

        @include responsive($tabletSmall) {
          @include font-vw(160);
          margin-bottom: get-vw(140);
        }
    }

    &__theme {
        @include flex(flex-start, space-between);
        flex-wrap: wrap;

        @include responsive($tabletSmall) {
            flex-wrap: nowrap;
        }

        &:not(:last-child) {
            margin-bottom: 60px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(140);
            }
        }

        > * {
            width: 100%;

            @include responsive($tabletSmall) {
                max-width: 50%;
            }
        }
        
        h3 {
            font-size: 26px;
            margin-bottom: 30px;
            max-width: 75%;

            @include responsive($tabletSmall) {
                position: sticky;
                top: get-vw(120);
                @include font-vw(38);
                margin-bottom: 0;
                max-width: 35%;
            }
        }
    }

    &__question {
        padding-bottom: 30px;
        border-bottom: 1px solid var(--grey-l2);

        @include responsive($tabletSmall) {
            padding-bottom: get-vw(30);
        }

        &.--active {
            padding-bottom: 0;

            .arrow {
                transform: rotate(180deg);
            }

            .content {
                height: auto;

                .wswyg--content {
                    transform: translateY(0);
                    transition: transform 640ms $ease;
                }
            }
        }

        &:not(:first-child) {
            margin-top: 30px;
        }

        .question {
            @include flex(center, space-between);
            gap: 10%;
            font-size: 18px;
            line-height: 1.3;
            font-weight: 600;

            @include responsive($tabletSmall) {
                @include font-vw(24);
            }

            .arrow {
                transition: transform 640ms $ease;
            }
        }

        .content {
            overflow: hidden;
            height: 0px;

            .wswyg--content {
                padding-top: 20px;
                padding-bottom: 30px;
                transform: translateY(40px);

                @include responsive($tabletSmall) {
                    padding-top: get-vw(20);
                    padding-bottom: get-vw(30);
                }
            }
        }
    }
}