.Infos {
    text-align: center;
    padding: 0;

    .container {
        padding-top: 60px;
        padding-bottom: 60px;

        @include responsive($tabletSmall) {
            padding-top: get-vw(120);
            padding-bottom: get-vw(120);
        }
    }

    h2 {
        font-size: 10vw;
        margin-bottom: 60px;

        @include responsive($tabletSmall) {
            @include font-vw(150);
            margin-bottom: get-vw(100);
        }
    }

    .wswyg--content {
        text-align: center;
        max-width: 760px;
        margin: 0 auto;

        @include responsive($tabletSmall) {
            max-width: get-vw(760);
        }

        h4 {
            font-weight: bold;
            margin-bottom: 20px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(20);
            }
        }

        ul {
            display: inline-block;
            color: var(--base);
            margin-top: 0;
            text-align: left;
        }
    }

    &__gallery {
        padding: space(0.5);
        @include flex(stretch, flex-start);
        gap: space(0.5);

        .visual__container {
            height: get-vw(550);

            &:first-child {
                flex-grow: 2;
                min-width: 55%;
            }
        }
    }
}