.CharteEthiqueEngagement{
  .container > .wrapper{
    @include flex(flex-start);
    gap: 2em;
    
    @include responsive($tabletSmall, max){
      flex-direction: column;      
    }

    > .head{
      @include responsive($tabletSmall){
        max-width: get-vw(830);
      }

      h2{
        font: 900 8vw / 1 var(--main-font);
        text-transform: uppercase;
        margin-bottom: 0.5em;

         @include responsive($tabletSmall){
           @include font-vw(70);
         }
      }
    }

    > .wswyg--content{
      @include responsive($tabletSmall){
        max-width: get-vw(450);
        margin-left: auto;
      }
    }
  }

  .container > .banner{
    position: relative;
    padding: 7em 2em;
    background-color: var(--black);
    margin-top: 5em;

    @include responsive($tabletSmall){
      padding: 10em 4em;
    }

    > span{
      font: 900 6vw / 1.07 var(--main-font);
      text-transform: uppercase;
      text-align: center;
      color: var(--white);
      display: block;
      position: relative;
      z-index: 1;

      @include responsive($tabletSmall){
        @include font-vw(80);
      }

      strong{
        color: var(--yellow);
        font-weight: inherit;
      }
    }

    > .visual__container{
      @include cover();
      z-index: 0;
    }
  }
}
