$menuBreakpoint: $desk;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  // transition: transform 1200ms $easeOutExpo 0ms;

  .--loading & {
    // transform: translateY(-120%);
    // transition: transform 1200ms $easeInExpo 240ms;
  }

  .--scrolled & {
    .container {
      padding: 14px;
      background: var(--white);

      @include responsive($menuBreakpoint) {
        padding-left: 30px;
      }

      
      .Logo {
        @include responsive($menuBreakpoint) {
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .l24__baseline { 
          opacity: 0;
          transform: translateX(-20%);

          @include responsive($menuBreakpoint) {
            transform: translateX(-100%);
          }
        }
        
        .l24__numbers {
          width: 50px;
          transform: translateX(-200%);

          @include responsive($tabletSmall) {
            transform: translateX(-100%);
          }

          @include responsive($menuBreakpoint) {
            width: get-vw(50);
            
          }
        }
      }
    }
  }

  // pointer-events: none;

  .container {
    width: 100%;
    max-width: none;
    @include flex(center, space-between);
    padding-top: 30px;
    padding-bottom: 30px;
    transition: padding 420ms $ease, background 420ms $ease;

    @include responsive($tabletSmall) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .Logo {
      transition: transform 420ms $ease;
      @include responsive($menuBreakpoint) {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translate(-50%, 0%);
      }

      .l24__baseline { transition: transform 640ms $ease, opacity 240ms $ease; }
      .l24__numbers {}
    }

    .--footerOnly {
      display: none;
    }
  }

  body:not(.--scrolled):not(.showMenu) &.--w {
    .container .Logo svg > * {
      fill: var(--white);
    }
  }
}
