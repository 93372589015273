.Page__header{
  position: relative;
  padding-top: 240px;
  padding-bottom: 40px;

  @include responsive($tabletSmall) {
    padding-top: get-vw(240);
    padding-bottom: get-vw(40);
  }

  &.--visual {
    color: var(--white);

    .container p {
      color: var(--yellow);
    }
  }

  .container{
    z-index: 10;

    @include responsive($tabletSmall) {
      padding: 0 space(0.5);
    }

    .wrapper {
      position: relative;
    }
    
    h1 {      
      overflow: hidden;

      .Page & {
        text-align: center;
        font-size: 48px;
        margin: 0 auto;

        @include responsive($tabletSmall) {
            @include font-vw(64);
            max-width: get-vw(840);
        }

        span {
          text-align: center;
        }
      }

      .--oh {
        position: relative;
        padding-top: 0.14em;

        .Home & {
          &:nth-child(1) {
            @include responsive($tabletSmall) {
              span {
                left: 20%;
              }
            }
          }

          &:nth-child(3) {
            @include responsive($tabletSmall) {
              span {
                left: 25%;
              }
            }
          }
        }

        .CharteEthique & {
          color: $black;

          &:nth-child(1) {
            @include responsive($tabletSmall) {
              span {
                left: 20%;
              }
            }
          }

          &:nth-child(3) {
            @include responsive($tabletSmall) {
              span {
                text-align: right;
              }
            }
          }
        }

        .Ecole & {
          &:nth-child(1) {
            @include responsive($tabletSmall) {
              span {
                left: 6%;
              }
            }
          }

          &:nth-child(2) {
            @include responsive($tabletSmall) {
              span {
                left: 14%;
              }
            }
          }

          &:nth-child(4) {
            @include responsive($tabletSmall) {
              span {
                left: 10%;
              }
            }
          }
        }

        .Campus__page & {
          &:nth-child(2) {
            @include responsive($tabletSmall) {
              span {
                left: 16%;
              }
            }
          }
        }

        .Pedagogie__page & {
          &:nth-child(1) {
            @include responsive($tabletSmall) {
              span {
                left: 16%;
              }
            }
          }
        }
      }
      
      span {
        display: block;
        position: relative;
        line-height: 1;
      }
    }

    p {
      font-weight: 900;
      line-height: 1.15;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      width: 100%;
      max-width: 400px;
      color: var(--base);

      @include responsive($tabletSmall) {
        position: absolute;
        margin-left: auto;
        font-size: get-vw(24);
        max-width: get-vw(400);
        bottom: 6%;
        right: 3%;
      }
      
      .CharteEthique &{
        @include responsive($tabletSmall){
          bottom: 18%;
          right: 0;
          max-width: get-vw(520);
        }
      }

      .Page & {
        position: relative;
        margin: 0 auto!important;
        bottom: 0!important;
        right: 0!important;
        max-width: 940px;

        @include responsive($tabletSmall) {
          max-width: get-vw(940);
        }
      }
    }
  }

  .visual__container {
    @include cover();
    z-index: 1;

    &:before {
      @include content();
      @include cover();
      background: var(--black);
      opacity: 0.6;
      z-index: 2;
    }
  }

  .Home &,
  .CharteEthique & {
    background: var(--yellow);
  }
}
