*:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) { all: unset; display: revert; }
*, *::after, *::before { box-sizing: border-box; }
a { cursor: revert; }
button{ cursor: pointer; }
span{ display: inline-block; }
menu, ol, ul { list-style: none; }
img { display: block; max-width: 100%; height: auto; }
svg { display: block; }
table { border-collapse: collapse; }
textarea { white-space: revert; }
:where([hidden]) { display: none; }
:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}
:where([draggable="true"]) { -webkit-user-drag: element; }
a:not([class]) {
  color: var(--links-color);
  text-decoration: none;

  &:focus {
    text-decoration: underline;
  }
}

a[class] {
  color: inherit;
  text-decoration: none;
}