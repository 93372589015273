.Footer {
   background: var(--yellow);
   color: var(--grey-d);
   position: relative;
   bottom:0;
   left:0;
   z-index:1;
   width:100%;
   overflow: hidden;

   .container {
      padding-top: 40px;
      padding-bottom: 40px;

      @include responsive($tabletSmall) {
         @include flex(initial, space-between);
         padding-top: space(1);
         padding-bottom: space(1);
      }

      .cta {
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         gap: 10px;

         .Btn {
            align-self: flex-start;
         }

         .--headerOnly {
            display: none;
         }
      }

      nav {
         li {
            margin-bottom: 20px;

            a {
               font-size: 16px;
               font-weight: 900;
               text-transform: uppercase;
            }
         }
      }
   }

   &__top {
      .container {
         @include responsive($tabletSmall, max) {
            display: flex;
            flex-direction: column-reverse;
         }
      }
   }

   &__bottom {

      .container {
         align-items: flex-end;
      }

      .Socials {
         justify-content: center;

         @include responsive($tabletSmall) {
            align-items: center;
            justify-content: flex-end;
         }
      }

      .links {
         display: flex;
         flex-direction: column;
         align-items: center;
         font-size: 10px;
         margin-top: 20px;

         @include responsive($tabletSmall) {
           flex-direction: row;
         }
        
         a {
            font-weight: 900;
            text-transform: uppercase;
            white-space: nowrap;
         }

         li {
            padding-right: 10px;
         }
      }
   }

   .partners__list {
      @include flex(center, center);
      flex-wrap: wrap;
      gap: 40px;
      flex-grow: 2;
      margin-bottom: 60px;
      mix-blend-mode: multiply;

      @include responsive($tabletSmall) {
         justify-content: flex-start;
         gap: get-vw(60);
         margin-bottom: 0;
      }

      img {
         max-width: 180px;
         max-height: 80px;
      }
   }

   .signature {
      margin-top: 60px;
      max-width: 320px;
      margin-bottom: 40px;

      @include responsive($tabletSmall) {
         max-width: space(6);
         margin-top: get-vw(120);
         margin-left: auto;
         margin-bottom: 0;
      }

      h5 {
         font-size: 11px;
         font-weight: bold;
         font-weight: 900;
         text-transform: uppercase;

         @include responsive($tabletSmall) {
            @include font-vw(11);
         }
      }

      img {
         width: 100%;
         height: auto;
         max-width: 220px;

         @include responsive($tabletSmall) {
            max-width: get-vw(220);
         }
      }

      span {
         font-size: 8px;
         font-weight: bold;
         font-weight: 900;
         text-transform: uppercase;

         @include responsive($tabletSmall) {
            @include font-vw(8);
         }
      }

      p {
         font-family: var(--main-font);
         color: var(--base);
         font-weight: 500;
      }
   }
}
