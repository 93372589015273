.Cursor {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 40px;
  z-index: 1000;
  pointer-events: none;
  mix-blend-mode: exclusion;

  &__custom {
    @include cover();
    transition: transform 400ms $ease;

    &:after {
      @include content();
      @include cover();
      border: 2px solid var(--links-color);
      border-radius: 40px;
    }

    &:before {
      @include content();
      @include cover();
      background: var(--links-color);
      border-radius: 40px;
      opacity: 0;
      transition: opacity 400ms $ease;
    }
  }

  body.--loading & {

    .Cursor__custom {
      transform: scale(0.3);

      &:after {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }
  }
}
