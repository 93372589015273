.Pagination {
  @include flex(center, center);
  gap: 6px;

  li {
    a {
      @include flex(center, center);
      padding: 6px 15px;
      font-weight: 900;
      text-decoration: none!important;

      @include noTouchHover() {
        background: var(--grey-l);
      }

      &.--active {
        background: var(--yellow)!important;
      }
    }
  }
}