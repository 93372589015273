.Partners {
    overflow: hidden;
    position: relative;

    &:before {
        @include responsive($tabletSmall) {
            @include content();
            @include cover();
            width: 60%;
            background: linear-gradient(90deg, rgba($white, 1) 70%, rgba($white, 0) 100%);
            z-index: 100;
            pointer-events: none
        }
    }

    .container {
        @include responsive($tabletSmall) {
            @include flex(center, space-between);
            gap: space(1);
        }

        > * {
            width: 100%;

            @include responsive($tabletSmall) {
                max-width: 50%;
            }
        }
    }

    &__title {
        flex-basis: 55%;
        position: relative;
        z-index: 150;

        h3 {
            font-size: 8vw;
            
            @include responsive($tabletSmall){
                @include font-vw(80);
            }

            .--oh {
                position: relative;
        
                &:nth-child(2) {
                    @include responsive($tabletSmall) {
                        left: 15%
                    }
                }
    
                &:nth-child(4) {
                    @include responsive($tabletSmall) {
                        left: 15%;
                    }
                }
            }

            span {
                display: block;
                position: relative;
            }
        }

        .Btn {
            margin-top: 20px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(40);
                position: absolute;
                bottom: 5%;
                left: 38%;
            }
        }
    }

    &__swiper {
        margin-top: 40px;

        @include responsive($tabletSmall) {
            padding: 0 space(0.5);
            margin-top: 0;
        }

        .swiper-slide {
            @include flex(center, center);
            flex-wrap: wrap;
            gap: 30px 60px;

            @include responsive($tabletSmall) {
                gap: get-vw(30) get-vw(60);
            }
        }
    }
}

.Partner {
    &__item {
        flex: 1 1 0px;
        
        .visual__container {
            .visual {
                position: relative;
                transform: scale(1);

                img {
                    position: relative;
                    object-fit: contain;
                    max-height: 100px;
                    max-width: 200px;
                    width: auto;
                    height: auto;
                    margin: 0 auto;

                    @include responsive($tabletSmall) {
                        max-height: get-vw(100);
                        max-width: get-vw(200);
                    }

                    &.--plchldr {
                        display: none;
                    }
                }
            }
        }

        .legend {
            display: block;
            color: #283A7F;
            font-size: 10px;
            line-height: 1;
            max-width: 180px;
            min-width: 180px;
            text-align: center;
            margin: 6px auto 0;
        }
    }
}