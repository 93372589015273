.TestimoniesSlider{
  background-color: var(--grey-d);
  position: relative;
  
  .swiper{
    .swiper-wrapper,
    .swiper-slide{
      transition: transform 1500ms $ease,
                  opacity 1500ms $ease !important;
    }

    .swiper-slide{
      &:not(.swiper-slide-active) {}

      &.swiper-slide-active{}

      .testimony__card{
        height: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .swiper-navigation{
      position: absolute;
      top: 100%; left: 0; 
      z-index: 10;
      margin: 0;
      gap: 0;

      > *{
        background: none;

        svg > *{
          stroke: var(--yellow);
        }
      }
    }
  }

  .swiper-timeline{
    --progress: 0;

    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0; left: 0;
    z-index: 10;
    background-color: var(--yellow);
    transform-origin: 0% 0%;
    transform: scaleX(var(--progress));
  }
}
