.Scrolling__text {
    background: var(--black);
    overflow: hidden;
    padding-bottom: 0;

    @include responsive($tabletSmall) {
      padding-top: 25vw;
    }

    div[data-prllxfrom] {
      @include responsive($tabletSmall, max) {
        transform: none!important;
      }
    }

    h2 {
        color: var(--yellow);
        font-size: 10vw;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
        max-width: 80%;

        @include responsive($tabletSmall) {
          margin: 0 auto 30px;
          @include font-vw(150);
        }

        .--oh {
          position: relative;

          &:nth-child(2) {
            @include responsive($tabletSmall) {
              transform: translateX(40%);
            }
          }

          &:nth-child(3) {
            @include responsive($tabletSmall) {
              transform: translateX(10%);
            }
          }

          &:nth-child(4) {
            @include responsive($tabletSmall) {
              transform: translateX(20%);
            }
          }
        }

        span {
            display: block;
            position: relative;
        }
    }

    .wrapper {
      position: relative;
      z-index: 100;
      padding: 40px 0 80px;
      background: linear-gradient(0deg, var(--black) 45%, rgba($black, 0) 100%);

      @include responsive($tabletSmall) {
        padding: 25vw 0;
      }
    }

    .text {
        @include responsive($tabletSmall) {
          max-width: get-vw(850);
          margin: auto;
        }

        p {
            color: #D3D3D3;
            font-size: 16px;
            font-weight: 500;
            line-height: 180%;  

            @include responsive($tabletSmall) {
              @include font-vw(24);
            }
        }
    
        .subtitle {
            color: var(--white);
            font-size: 22px;
            line-height: 160%;
            margin-bottom: 40px;

            @include responsive($tabletSmall) {
                @include font-vw(34);
              }
        }
    }
}