.Newsletter {
  margin-top: 50px;
  width: 100%;
  max-width: 450px;

  @include responsive($tabletSmall, max) {
    margin-right: 0px !important;
    width: 100% !important;
  }

  label {
    font-size: 16px;
     font-weight: 900;
     text-transform: uppercase;
     margin-bottom: 10px;
     display: block;
  }

  .Form__container {
    > div {
      @include responsive($tabletSmall) {
        @include flex(stretch, space-between);
        flex-wrap: nowrap;
      }
    }

    > p {
      font-size: 12px;
      margin-top: 20px;
      color: var(--base);

      a{
        font: inherit;
        font-weight: 600;
      }
    }

    .Form__group {
      width: 100%;

      .Form__control {
        font-weight: 500;
        background: #e9c60b;
        border: 0;
        color: var(--base);
        font-size: 16px;
        padding: 0 20px;
        height: 100%;
        width: 100%;
        min-height: 50px;
        border-bottom: 1px solid #e9c60b;
        transition: color 400ms $ease, border 400ms $ease;

        &::placeholder {
          color: var(--base);
          opacity: 0.5;
        }

        &:focus {
          color: var(--base);
          outline: none;
          border-color: var(--base);
        }
      }
    }
  }

  .Btn {
    margin-top: 0;
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;

    @include responsive($tabletSmall, max) {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }
  }

  .Form__output {
    p:not(:empty) {
      font-size: 12px;
      font-weight: 700;
      padding: 20px;
      background-color: var(--base);
      color: var(--white);
    }
  }
}