.Contact {
  background: var(--black);

  .container {
    padding: 0 space(2);

    h3 {
      color: var(--yellow);
      font-size: 12vw;
      font-weight: 900;
      line-height: 100%;
      text-transform: uppercase;
      margin-bottom: 40px;

      @include responsive($tabletSmall) {
        @include font-vw(150);
        margin-bottom: get-vw(60);
      }
    }
  }

  .row {
    @include responsive($tabletSmall) {
      @include flex(flex-start, space-between);
      gap: space(2);
    }

    > * {
      @include responsive($tabletSmall) {
        flex: 1 1 0px;
      }
    }
  }

  .content {
    @include responsive($tabletSmall, max) {
      margin-bottom: 40px;
    }

    p {
      color: var(--white);
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 1;
      font-family: var(--main-font);
      max-width: 440px;

      @include responsive($tabletSmall) {
        @include font-vw(38);
        max-width: 100%;
      }
    }

    .Btn {
      margin-top: 40px;

      @include responsive($tabletSmall) {
        margin-top: get-vw(40);
      }
    }
  }

  address {
    color: var(--grey);
    p, a { color: inherit; }
  }

  .Socials {
    margin-top: 30px;

    @include responsive($tabletSmall) {
      margin-top: get-vw(30);
    }

    svg {
      path {
        fill: var(--white);
      }
    }
  }

}