.Copyright {
   @include flex(center, center);

   span {
    display: block;
   }

   .wkn {
      position: relative;
      margin-left: 5px;
      opacity: 0.4;
      transition: opacity 240ms $ease;
      top: -0.1em;

      &+span {
        margin-left: 5px;
        font-style: italic;
        font-family: serif;
      }
   }

   @include noTouchHover() {
     .wkn {
       opacity: 1;

       &+span {
         opacity: 1;
       }
     }
   }
}
