.Link {
  position: relative;
  font-size: 24px;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--base);
    transform-origin: right center;
    transition: transform 400ms $easeInOutQuad 50ms;
  }

  &:before {
    transform: scaleX(0);
    transform-origin: left center;
    transition-delay: 0;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transition-delay: 250ms;
    }

    &:after {
      transform: scaleX(0);
      transition-delay: 0;
    }
  }

  &.--min {
    font-size: 16px;
    font-weight: 500;
  }
}

.Backlink {
  @include flex(center, center, inline-flex);
  gap: 10px;
  opacity: 0.5;
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 700;
  transition: opacity 420ms $ease;

  @include responsive($tabletSmall) {
    @include font-vw(16);
  }

  @include noTouchHover() {
    opacity: 1;
  }

  .arrow {
    position: relative;
    @include imgRatio(21, 21);
    width: 21px;

    @include responsive($tabletSmall) {
      width: get-vw(21);
    }

    svg {
      @include cover();
      display: block;
      overflow: visible;
      transform: rotate(180deg);

       path {
        stroke: var(--white);
        stroke-linecap: round;
       }
    }
  }
}