.Single {
  &__page {
    .Page__header {
      text-align: center;
      background: var(--black);
      color: var(--white);
      padding-bottom: 80px;

      @include responsive($tabletSmall) {
        padding-bottom: space(4);
      }

      h1 {
        text-align: center;
        font-size: 48px;
        margin: 0 auto;

        @include responsive($tabletSmall) {
            @include font-vw(48);
            max-width: get-vw(840);
        }
      }

      .date {
        display: block;
        color: var(--yellow);
        font-weight: 900;
        text-transform: uppercase;
        margin-top: 20px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(20);
        }
      }
    }
  }

  &__visual {
    margin-top: space(-2);

    .visual__container {
      @include imgRatio(920, 450, var(--base));
      margin: 0 auto;

      @include responsive($tabletSmall) {
          max-width: get-vw(920);
      }
    }
  }

  &__content {

    .wswyg--content {
      padding: 40px 0;
      max-width: 840px;
      margin: 0 auto;

      @include responsive($tabletSmall) {
          max-width: get-vw(840);
          padding: get-vw(60) 0;
      }

      p:first-child {
          font-size: 24px;
          line-height: 1.5;
          font-weight: 500;

          @include responsive($tabletSmall){
            @include font-vw(24);
          }
      }
    }
  }
}