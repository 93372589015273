.Page__404 {
  .Page__header {
    text-align: center;
    
    p {
      position: relative!important;
      margin: 0 0 20px;
      max-width: 100%;
      bottom: 0;
      right: 0;
    }
  }
}