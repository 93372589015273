.Campus {
  &__page {
    .Page__header {
      @include responsive($tabletSmall) {
        padding-bottom: 25vh;
      }

      .visual__container {
        &:after {
          @include content();
          @include cover();
          background: linear-gradient(180deg, rgba(12, 12, 12, 0.00) 80%, var(--black) 100%);
        }
      }

      .container p {
        color: var(--white);
      }
    }
  }
}