.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  margin-right: auto;
  overflow:hidden;

  .wkn {
    display: block;
  }

  svg > * {

    html.--dark & {
      fill: var(--white);
    }
  }

  .Intro & {
    margin-left: auto;

    svg > * {
      fill: var(--white);
    }
  }
}