.Campus {
  position: relative;
  background: var(--black);
  padding: 0;

  @include responsive($tabletSmall, max) {
    overflow: hidden;
  }

  .container {
    position: relative;
    z-index: 10;
    padding: space(3) space(1) space(5);

    &:before {
      @include content();
      @include cover();
      z-index: -1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 40%);
    }
  }

  .headline {
    text-align: center;
    color: var(--white);
    margin-bottom: 40px;

    h3 {
      font-size: 11vw;

      @include responsive($tabletSmall) {
        @include font-vw(150);
      }
    }

    > span {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;

      @include responsive($tabletSmall) {
        @include font-vw(24);
      }
    }
  }

  .titles {
    color: var(--white);

    h3:not(:last-child) {
      margin-bottom: 30vh;
    }
  }

  .text {
    text-align: center;

    .wswyg--content {
      margin: 0 auto;
      text-align: center;

      @include responsive($tabletSmall) {
        max-width: get-vw(820);
      }

      p {
        color: var(--white);
        font-size: 16px;
        font-weight: 500;
        line-height: 130%; 
  
        @include responsive($tabletSmall) {
          @include font-vw(16);
        }
      }
    }
    
    .Btn {
      margin: 40px auto 0; 

      @include responsive($tabletSmall) {
        margin: get-vw(40) auto 0; 
      }
    }
  }

  &__bg {
    @include cover();
    z-index: 0;

    .visual__container {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
  }
}