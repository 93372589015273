.Btn {
  @include flex(center, center, inline-flex);
  text-align: center;
  background: var(--yellow);
  border: 2px solid var(--yellow);
  color: var(--grey-d);
  padding: 12px 20px;
  gap: 10px;
  outline: 0;
  cursor: pointer;
  text-decoration: none!important;
  overflow: hidden;

  @include responsiveMinMax($tabletSmall, $deskXL) {
    padding: get-vw(12) get-vw(20);
    border-width: get-vw(2);
    gap: get-vw(10);
  }

  &.--arrowed {
    @include noTouchHover() {
      .Btn__txt {
        transform: translateX(15px);

        @include responsiveMinMax($tabletSmall, $deskXL) {
          transform: translateX(get-vw(16));
        }
      }
      .Btn__arrow {
        transform: translateX(200%);
      }
    }
  }

  &__txt {
    display: block;
    color: inherit;
    font-weight: bold;
    font-size: 16px;
    transition: transform 480ms $ease;

    @include responsiveMinMax($tabletSmall, $deskXL) {
      @include font-vw(16);
    }
  }

  &__arrow {
    position: relative;
    @include imgRatio(21, 21);
    width: 20px;
    transition: transform 420ms $ease;

    @include responsiveMinMax($tabletSmall, $deskXL) {
      width: get-vw(20);
    }

    > .arrow {
      @include cover();

      svg {
        @include cover();
        overflow: visible;
        stroke-linecap: round;
      }
    }
  }

  &.--dark {
    background: var(--base);
    border-color: var(--base)!important;
    color: var(--white);

    .arrow {
      svg {
        path { 
          stroke: var(--white);
        }
      }
    }
  }
  
  &.--transparent {
    color: var(--base);
    border-color: var(--base)!important;
    background: transparent;
  }

  &.--min {
     padding: 8px 13px 7px;

    .Btn__txt {
      font-size: 11px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}