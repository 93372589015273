.Form {
  .--error {
    color: var(--error-color)!important;
  }

  .row {
    @include flex(flex-start, flex-start);

    @include responsive($tabletSmall) {
      margin-right: -30px;
    }

    > * {
      width: 100%;
      margin-bottom: 30px;

      @include responsive($tabletSmall) {
        max-width: calc(50% - 30px);
        margin-right: 30px;
      }

      &.--full {
        @include responsive($tabletSmall) {
          max-width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          margin-right: 30px;
        }
      }
    }
  }

  label:not(.Checkbox) {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.1em;
    background: var(--white);
    margin-bottom: 8px;
    padding: 0;
  }

  &__group {
    position: relative;
    width: 100%;

    &:not(.--textarea):not(.--select) {
      label {
        position: absolute;
        top: 21px;
        left: 10px;
        opacity: 0.3;
        transform: translateY(-50%) scale(1);
        transition: all 400ms $easeOutExpo;
        transform-origin: left center;
        padding: 0px 10px;
      }

      &.--focused,
      &.--filled {
        label {
          position: absolute;
          left: 10px;
          opacity: 1;
          transform: translateY(-26px) scale(0.8);
        }
      }
    }

    span.--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 10px;
      padding-top: 2px;
    }
  }

  &__control {
    width: 100%;
    min-height: 42px;
    padding: 0 14px;
    border-radius: 0;
    outline: 0;
    border: 1px solid var(--grey);
    box-shadow: none;

    .--error & {
      border-color: var(--error-color);
    }

    &::placeholder {
      opacity: 0;
    }
  }

  select.Form__control {
    line-height: 42px;
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 30px;
  }

  &__actions {
    .Btn {
      margin-top: 0;
    }
  }

  &__output {
    color: var(--valid-color);

    p:not(:empty) {
      margin-top: 20px;
    }
  }
}

.Radios,
.Checkboxs {
  margin-bottom: -10px;

  @include responsive($tabletSmall) {
    @include flex(center, flex-start, inline-flex);
  }

  > * {
    margin-bottom: 10px;
    @include responsive($tabletSmall) {
      margin-right: 40px;
    }
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: var(--base);

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1px solid var(--base);
    border-radius: 2px;
    margin-right: 10px;
    transition: background 240ms $easeOutQuad;

    .--radio & {
      border-radius: 18px;

      &:after {
        @include content;
        width: 10px;
        height: 10px;
        background: var(--white);
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $easeOutQuad;
      }
    }

    .check {
      position: relative;
      width: 66%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: var(--white);
          stroke-width: 6px;
          stroke-linecap: round;
        }
      }
    }
  }

  &__label {
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0;
    font-weight: 500;
  }
}
