.TextColumns {
    background: var(--black);
    color: var(--white);

    &.--yellow {
        background: var(--yellow);
        color: var(--font-color);

        h3 {
            @include responsive($tabletSmall) {
                @include font-vw(28);
            }

            strong {
                font-weight: bold;
                color: var(--yellow);
            }
        }

        .TextColumns__item:not(:first-child):before {
            border-top-color: var(--brun);
            opacity: 0.2;
        }

        .wswyg--content {
            p {
                color: var(--font-color-brun)!important;
            }

            ul li:before {
                background:var(--font-color-brun)!important;
            }
        }
    }

    h2 {
        font-size: 12vw;

        @include responsive($tabletSmall) {
          @include font-vw(160);
        }

        .--oh {
              &:nth-child(2) span {
                @include responsive($tabletSmall) {
                    left: 16%;
                }
            }
          }
    }

    &__item {
        position: relative;
        min-width: 100%;

        &:not(:first-child) {
            margin-top: 40px;
            padding-top: 40px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(80);
                padding-top: get-vw(80);
            }

            &:before {
                @include content();
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                border-top: 1px solid var(--base);
            }
        }

        .inner {
            @include flex(flex-start, space-between);
            flex-wrap: wrap;

            > * {
                width: 100%;

                @include responsive($tabletSmall) {
                    max-width: 50%;
                }
            }

            h3 {
                font-size: 7.2vw;
                margin-bottom: 40px;

                @include responsive($tabletSmall) {
                    position: sticky;
                    top: 120px;
                    @include font-vw(38);
                    margin-bottom: 0;
                    max-width: 40%;
                }

                strong {
                    font-weight: bold;
                    color: var(--yellow);
                }
            }

            .wrapper{
                @include responsive($tabletSmall) {
                    max-width: space(10);
                }

                .wswyg--content {
                    p {
                        color: var(--text-grey);
                    }
    
                    ul li:before {
                        background:var(--text-grey); 
                    }
                }

                .Btn{
                    margin-top: 2em;
                }
            }

        }
    }

    .visual__container {
        margin-top: 60px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(120);
        }
    }
}