.reinsurance {
    background: var(--grey-l);
    padding: 80px 30px;
    overflow: hidden;

    @include responsiveMinMax($tabletSmall, $deskXL) {
        padding: get-vw(120) get-vw(60);
    }

    &__title {
        h2 {
            font-size: 9vw;
            font-weight: 900;
            line-height: 1;
            text-transform: uppercase;
            overflow: hidden;
            text-align: left;
    
            @include responsive($tabletSmall) {
                @include font-vw(100);
            }

            .--oh {
                position: relative;

                &:nth-child(1) {
                    @include responsive($tabletSmall) {
                        left: 16%
                    }
                }
      
                  &:nth-child(3) {
                    @include responsive($tabletSmall) {
                        left: 6%;
                    }
                }
            }

            span {
                display: block;
                position: relative;
            }
        }
    
        .subtitles {
            @include responsive($tabletSmall) {
                @include flex(initial,space-between);
            }
    
            .subtitle {
                color: var(--black);
                font-size: 24px;
                font-weight: 900;
                line-height: 120%;
                text-transform: uppercase;
                
                @include responsive($tabletSmall, max) {
                  margin-bottom: 20px;
                }
    
                @include responsive($tabletSmall) {
                  @include font-vw(28);
                  flex-basis: 51%;
                }
            }

            .text {
                flex-basis: 40%;

                p:not(.subtitle) {
                    font-family: 'Maison Neue Txt', sans-serif;
                }

                .Btn {
                    margin-top: 20px;

                    @include responsive($tabletSmall) {
                        margin-top: get-vw(40);
                    }
                }
            }
           
        }
    }
    
    &__list {
        margin-top: 60px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(120);
        }

        .line {
            position: relative;
            overflow: hidden;

            &:not(:last-child):after {
                @include content();
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border-bottom: 2px solid #EAEAEA;
            }

            &[data-inview] {
                &:not(.--visible) {
                    &:after {
                        transform: scaleX(0);
                    }
                    .inner > * {
                        transform: translateX(110%);
                        
                        @include responsive($tabletSmall) {
                            transform: translateY(110%);
                        }
                    }
                }

                &.--visible {
                    &:after {
                        transition: transform 2400ms $ease;
                    }
                    .inner > * {
                        transition: transform 1200ms $ease;
                    }
                }
            }

            .inner {
                padding: 30px 0;
                @include flex(stretch,space-between);

                @include responsive($tabletSmall) {
                    padding: get-vw(30) 0;
                    padding-right: get-vw(70);
                }

                @include responsive($tabletSmall, max) {
                    flex-direction: column;
                    align-items: initial;
                }
            }

            h3 {
                font-size: 5.5vw;
                font-weight: 900;
                line-height: 1;
                text-transform: uppercase;
                flex-basis: 34%;

                @include responsive($tabletSmall, max) {
                  margin-bottom: 15px
                }
        
                @include responsive($tabletSmall) {
                    @include font-vw(40);
                }
            }

            p {
                font-family: 'Maison Neue Txt', sans-serif;
                flex-basis: 42%;
            }

            img {
                max-width: 100px;
                min-width: 100px;
                height: auto;
                object-fit: contain;

                @include responsive($tabletSmall, max) {
                  order: -1;
                  margin-bottom: 20px;
                }

                @include responsiveMinMax($tabletSmall, $deskXL) {
                    max-width: get-vw(100);
                    min-width: get-vw(100);
                }
            }
        }
    }
}

.Ecole {
    .reinsurance__title {
        h2 {
            color: var(--text);

            span:last-child {
                color: var(--grey-d)
            }
        }
    }
}