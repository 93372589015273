.news_block {
    
    .container {
        @include responsive($tabletSmall) {
            padding: space(1.5) space(2);
        }
    }

    .news__title {
        position: relative;
        max-width: 80%;

        h3 {
            font-size: 8vw;

            @include responsive($tabletSmall){
                @include font-vw(80);
            }
        }

        .Btn {
            margin-top: 20px;

            @include responsive($tabletSmall) {
                position: absolute;
                top: -3%;
                bottom: 0;
                margin: auto;
                max-height: max-content;
                left: 53%;
            }
        }
    }

    .news__list {
        @include flex(initial,space-between);
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 40px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(40);
            gap: get-vw(60);
        }

        > * {
            flex: 1 1 0px;

            @include responsive($tabletSmall, max) {
                min-width: 100%;
            }
        }
    }
}

.news__card {

    &.--featured {

        a {
            display: block;
            position: relative;
            height: 100%;

            .cover {
                @include responsive($tabletSmall) {
                    min-height: get-vw(340);
                    height: 100%;
                }
            }

            .visual__container {
                background: var(--black);

                @include responsive($tabletSmall, max) {
                    transform: none!important;
                }

                @include responsive($tabletSmall) {
                    height: 100%;
                }

                &:before {
                    padding-top: 0;

                    @include responsive($tabletSmall, max) {
                        padding-top: 240px;
                    }
                }

                &:after {
                    @include content();
                    @include cover();
                    background: linear-gradient(0deg, #0C0C0C 0%, rgba(12, 12, 12, 0.00) 100%);
                    width: auto;
                    right: 0;
                    z-index: 5;

                    @include responsive($tabletSmall) {
                        background: linear-gradient(270deg, #0C0C0C 45%, rgba(12, 12, 12, 0.00) 100%);
                    }
                }

                .visual {
                    @include responsive($tabletSmall) {
                        max-width: 65%;
                    }
                }
            }

            .content {
                position: relative;
                padding: 30px;
                z-index: 10;
                margin-top: 0;
                padding-top: 0;
                background: var(--black);

                @include responsive($tabletSmall) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: get-vw(30);
                    width: 50%;
                    background: transparent;
                }

                .date {
                    color: var(--yellow);
                }
                
                h4 {
                    color: var(--white);
                    font-size: 32px;

                    @include responsive($tabletSmall) {
                        @include font-vw(32);
                    }
                }
            }
        }
    }

    a {
        text-decoration: none!important;

        @include noTouchHover() {
            .cover {
                .visual__container {
                    transform: scale(0.95);

                    .visual {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .cover {
        position: relative;

        .visual__container {
            filter: grayscale(1);
            @include imgRatio(380, 250, var(--base));
            transition: transform 420ms $ease;

            .visual {
                transition: transform 420ms $ease;
            }
        }

        .category {
            background: var(--yellow);
            padding: 10px;
            font-size: 12px;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 20;

            @include responsive($tabletSmall) {
                padding: get-vw(10);
                @include font-vw(14);
            }
        }
    }

    .content {
        margin-top: 20px;
        
        @include responsive($tabletSmall) {
            margin-top: get-vw(20);
        }
    }

    .date {
        font-size: 16px;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
        margin: 23px 0 14px;
        color: #696969;

        @include responsive($tabletSmall) {
            @include font-vw(16);
            margin: get-vw(25) 0 get-vw(15);
        }
    }

    h4 {
        color: var(--Dark-grey, #282828);
        font-size: 24px;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;

        @include responsive($tabletSmall) {
            @include font-vw(24);
        }
    }
}