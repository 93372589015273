.AlertInfos{
  display: flex;
  // position: relative;
  position: fixed;
  bottom: 0; left: 0;
  width: 100%;
  z-index: 99999;
  background: var(--base);
  padding: 5px;
  pointer-events: all;
  padding: 50px 20px;
  clip-path: inset(0% 100% 0% 0%);

  // @include responsive($mobile, max){
  //   bottom: 76px;
  // }

  @include responsive($mobile){
    max-width: 350px;
  }

  &.--over-cookies{
    // bottom: 76px;
  }

  .inner{
    position: relative;

    > span{
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      display: block;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(white, 0.1);
      color: var(--yellow);
    }

    a:not([class]){
      text-decoration: none;
    }

    p, a:not([class]){
      font-size: 14px;
      text-align: left;
      color: white;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        @include font-vw(14);
      }
    }

    a{
      display: inline-block;
      margin-top: 1.5em;

      span{
        display: inline;
        background-image: linear-gradient(white, white);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        transition: background-size .3s;
      }
      
      @include noTouchHover() {
        span{
          background-position: 100% 100%;
          background-size: 0% 1px;
        }
      }
    }
  }

  button{
    all: unset;
    @include flex(center, center);
    height: 15px;
    width: 15px;
    padding: 5px;
    position: absolute;
    top: 0; right: 0;
    cursor: pointer;

    &:before,
    &:after{
      @include content();
      height: 1px;
      width: 10px;
      position: absolute;
      background: white;
    }

    &:before{
      transform: rotate(-45deg);
    }

    &:after{
      transform: rotate(45deg);
    }
  }
}