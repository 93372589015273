// Maison Neue
@font-face {
  font-family: 'Maison Neue Txt';
  src: url("../fonts/MaisonNeue-Book.woff2") format('woff2'), url("../fonts/MaisonNeue-Book.woff") format('woff');
  font-weight: 500;
  font-display: block;
  font-style: normal;
}

// Maison Neue Extended
@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-Light.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-Light.woff") format('woff');
  font-weight: 300;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-Book.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-Book.woff") format('woff');
  font-weight: 400;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-BookItalic.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-BookItalic.woff") format('woff');
  font-weight: 400;
  font-display: block;
  font-style: italic;
} 

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-Medium.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-Medium.woff") format('woff');
  font-weight: 500;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-MediumItalic.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-MediumItalic.woff") format('woff');
  font-weight: 500;
  font-display: block;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-Bold.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-Bold.woff") format('woff');
  font-weight: 700;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeueExtended-Black.woff2") format('woff2'), url("../fonts/MaisonNeueExtended-Black.woff") format('woff');
  font-weight: 900;
  font-display: block;
  font-style: normal;
}