.Pedagogie {
  position: relative;
  z-index: 1;

  &+* {
    position: relative;
    z-index: 2;
  }

  h2 {
    position: relative;
    left: 0%;

    .--oh {
      &:nth-child(1) span {
          @include responsive($tabletSmall) {
              left: 16%
          }
      }

      &:nth-child(2) span {
          @include responsive($tabletSmall) {
              left: 6%;
          }
      }

      &:nth-child(3) span {
        @include responsive($tabletSmall) {
            left: 10%;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 -25px;

    @include responsive($tabletSmall) {
      gap: get-vw(30);
      margin: 0;
    }
  }

  &__item {
    min-width: 100%;

    &:nth-child(even) .inner {
      background: var(--yellow);
    }

    .inner {
      background: var(--grey-l);
      padding: 40px 30px;

      @include responsive($tabletSmall) {
        padding: space(1.5);
        @include flex(flex-start, space-between);
      }

      h3 {
        font-size: 7.2vw;
        margin-bottom: 30px;

        @include responsive($tabletSmall) {
          @include font-vw(68);
          margin-bottom: 0;
          position: sticky;
          top: get-vw(120);
        }
      }

      .wswyg--content {
        @include responsive($tabletSmall) {
          max-width: 50%;
        }
        
        p {
          color: var(--grey-d);

          &:first-child {
            line-height: 1.3;

            @include responsive($tabletSmall) {
              @include font-vw(24);
            }
          }
        }
      }
    }
  }
}