.Icon {
  position: relative;
  
  svg {
    @include cover();
  }
  
  &.--play{
    @include imgRatio(1, 1);
    min-width: 24px;

    svg{
      fill: var(--base);
    }
  }

  &.--tick{
    @include imgRatio(14, 12);
    width: get-vw(14);

    @include responsive($tabletSmall, max){
      min-width: 14px;
    }

    svg{
      path{
        stroke: var(--yellow);
      }
    }
  }
}
