.TextImage {

    &.--yellow {
        background: var(--yellow);

        h2 {
            font-size: 14vw;

            @include responsive($tabletSmall) {
                @include font-vw(160);
            }
        }
    }

    h2 {
        margin-bottom: 50px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(50);
        }

        &.--splited {
            .--oh {
                &:nth-child(1) {
                    @include responsive($tabletSmall) {
                        span {
                            left: 14%;
                        }
                    }
                }
                &:nth-child(3) {
                    @include responsive($tabletSmall) {
                        span {
                            left: 6%;
                        }
                    }
                }
            }
        }
    }

    .content {
        @include flex(center, space-between);
        flex-wrap: wrap;
        gap: 40px;

        @include responsive($tabletSmall) {
            gap: space(1);
            flex-wrap: nowrap;
        }

        .visual__container {
            @include imgRatio(380, 250, var(--base));

            @include responsive($tabletSmall) {
                max-width: 380px;
            }
        }

        .wswyg--content {
            width: 100%;

            h3 {
                font-size: 28px;
                max-width: 75%;

                @include responsive($tabletSmall) {
                    @include font-vw(28);
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;

                @include responsive($tabletSmall) {
                    @include font-vw(24);
                }
            }

            .Btn {
                margin-top: 30px;
                
                @include responsive($tabletSmall) {
                   margin-top: get-vw(30);
                }
            }
        }
    }
}