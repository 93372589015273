h1, h2, h3, h4, h5, h6{
   font-weight: 500;
}

h1 {
   line-height: 1.05;
   margin-bottom: 0;
   font-size: 11vw;
   text-transform: uppercase;
   font-weight: 900;

   @include responsive($tabletSmall, max) {
      margin-bottom: 10px;
   }

   @include responsive($tabletSmall) {
      @include font-vw(150);
   }
}

h2 {
   font-size: 10vw;
   text-transform: uppercase;
   font-weight: 900;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(120);
   }

   &.--splited {
      font-size: 9vw;
      line-height: 1;

      @include responsive($tabletSmall) {
          @include font-vw(100);
      }
   }
}

h3 {
   font-size: 8vw;
   text-transform: uppercase;
   font-weight: 900;
   line-height: 100%;

   @include responsive($tabletSmall){
     @include font-vw(80);
   }

   &.--splited {
      font-size: 48px;
      line-height: 1;

      @include responsive($tabletSmall) {
          @include font-vw(48);
      }
   }
}

h4 {
   font: 400 22px var(--main-font);

   @include responsive($tabletSmall){
     @include font-vw(22);
   }
}

h5 {
   font: 400 20px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(20);
   }
}

h6 {
   font: 400 18px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(18);
   }
}

.--splited {
   > .--oh{
      padding-top: 0.1em;

      &:not(:first-child){
         margin-top: -0.1em;
      }

      span {
          display: block;
          position: relative;
      }
   }
}

sup {
   vertical-align: top;
   filter: opacity(0.95);

   @include responsive($tabletSmall, max) {
      font-size: 50px;
   }

   @include responsive($tabletSmall) {
      @include font-vw(50);
   }
}

strong {
   font-weight: 700;
}

em{
   font-style: italic;
}

p {
   font-size: 16px;
   line-height: 1.6;
   font-family: var(--main-font);
   color: var(--text);

   @include responsive($tabletSmall){
     @include font-vw(16);
   }

   &.no__results {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      opacity: 0.2;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }
   }
}

.intro {
   p {
      font-size: 18px;
      line-height: 1.6;

      @include responsive($tabletSmall){
      @include font-vw(24);
      }
   }
}

blockquote.quote {
   p {
      color: var(--white);
   }
}

.wswyg--content {
   text-align: left;

   h2 {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 30px;

      @include responsive($tabletSmall){
         @include font-vw(36);
         margin-bottom: get-vw(30);
      }
   }

   h3 {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 30px;

      @include responsive($tabletSmall){
         @include font-vw(24);
         margin-bottom: get-vw(30);
      }
   }

   h4 {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 30px;

      @include responsive($tabletSmall){
         @include font-vw(18);
         margin-bottom: get-vw(30);
      }
   }

   *+h2 {
      margin-top: 60px;

      @include responsive($tabletSmall){
         margin-top: get-vw(60);
      }
   }

   > * + * {
      margin-top: 30px;

      @include responsive($tabletSmall){
         margin-top: get-vw(30);
      }
   }

   ul,
   ol {
      @extend p;
      padding-left: 10px;

      // @include responsive($tabletSmall){
      //    padding-left: space(1);
      // }

      // > li + li {
      //    margin-top: 1em;
      // }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }

      li {
         @include responsive($tabletSmall){
            @include font-vw(16);
         }
      }
   }

   hr{
      border-bottom: 1px solid var(--text-grey);
   }

   ul{
      vertical-align: top;

      ul {
         margin: 20px 0;
      }

      li {
         position: relative;
         padding-left: 1em;

         &:before {
            position: absolute;
            top: 0.6em;
            left: 0;
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 0.3em;
            height: 0.3em;
            border-radius: 1em;
            background: var(--base);
         }
      }
   }

   ol {
      padding-left: 1.75em;
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(1) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 30px;
      border-left: 2px solid var(--grey);

      @include responsive($tabletSmall){
         padding-left: space(0.5);
      }

      p{
         font-weight: 500;
         font-size: 24px;
         line-height: 1.4;
         
         @include responsive($tabletSmall){
            @include font-vw(24);
         }
      }
   }

   a:not(.Btn) {
      color: var(--links-color);
      font-weight: bold;
      text-decoration: underline;
   }
}
