.redirection {
  background: var(--black);

  .container {
    @include responsive($tabletSmall) {
      padding: 3vw calc(100vw / 36) 3vw 25vw;
    }

    p {
      color: var(--white);
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      margin: 15px 0 25px;

      @include responsive($tabletSmall) {
        margin: get-vw(15) 0 get-vw(25);
        @include font-vw(24);
        max-width: 70%;
      }
    }

    h3 {
      color: var(--yellow);
      font-size: 12vw;
      font-weight: 900;
      line-height: 100%;
      text-transform: uppercase;

      @include responsive($tabletSmall) {
        @include font-vw(150);
      }

      .--oh {
        position: relative;

        &:first-child {
          @include responsive($tabletSmall) {
            left: -25%;
          }
        }
      }
    }
  }
}