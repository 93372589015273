.Team {
    overflow: hidden;

    .intro {
        @include responsive($tabletSmall) {
            max-width: 70%;
        }
    }

    h2 {
        .--oh {
            &:nth-child(1) span {
                @include responsive($tabletSmall) {
                    left: 6%
                }
            }
  
              &:nth-child(2) span {
                @include responsive($tabletSmall) {
                    left: 12%;
                }
            }
        }
    }

    &__categories {
        position: relative;
        margin-top: 40px;
        margin-bottom: 40px;
        z-index: 10;

        @include responsive($tabletSmall) {
            margin-top: get-vw(100);
            margin-bottom: 0;
        }

        ul {
            @include flex(center, flex-start);
            flex-wrap: wrap;
            gap: 20px;

            li {
                margin-bottom: -15px;
            }

            li.--active {
                a {
                    opacity: 1;

                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }

        a {
            position: relative;
            font-size: 15px;
            text-transform: uppercase;
            text-decoration: none!important;
            font-weight: 900;
            opacity: 0.5;
            transition: opacity 240ms $ease;

            @include responsive($tabletSmall) {
                @include font-vw(15);
            }

            &:after {
                @include content();
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                border-bottom: 2px solid var(--base);
                transform: scaleX(0);
                transition: transform 420ms $ease;
            }

            @include noTouchHover() {
                opacity: 1;
            }
        }
    }

    &__swipers {
        > div {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

            &.--active {
                position: relative;
                opacity: 1;
            }
        }
    }

    &__swiper {
        .swiper-slide {
            width: 100%;
            max-width: 350px;

            @include responsive($tabletSmall) {
                max-width: get-vw(350);
            }
        }
    }
}

.Teammate {
    &__card {
        .visual__container {
           @include imgRatio(350, 500, var(--base));
           margin-bottom: 16px;

           @include responsive($tabletSmall) {
                margin-bottom: get-vw(16);
           }
        }

        .content {
            span {
                display: block;
            }

            .name {
                font-weight: 900;
                font-size: 24px;
                line-height: 1.2;
                letter-spacing: -0.24px;

                @include responsive($tabletSmall) {
                    @include font-vw(24);
                }
            }

            .job {
                font-size: 16px;
                line-height: 1.2;

                @include responsive($tabletSmall) {
                    @include font-vw(16);
                }
            }
        }
    }
}