.VideoSection {
    position: relative;
    padding: space(1) 0;
    background: var(--black);

    .container {
        padding-left: space(0.5);
        padding-right: space(0.5);
    }

    .Home &:before {
        @include content();
        @include cover();
        height: 50%;
        background: var(--yellow);
    }

    &.--full {
        padding: 0;

        .container {
            padding: 0;
        }
    }
}