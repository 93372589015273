.Chiffres {
    background: var(--black);
    color: var(--white);

    .--splited {
        .--oh {
            &:nth-child(2) {
                @include responsive($tabletSmall) {
                    span {
                        left: 14%;
                    }
                }
            }
            &:nth-child(3) {
                @include responsive($tabletSmall) {
                    span {
                        left: 6%;
                    }
                }
            }
        }
    }

    &.--yellow {
        background: var(--yellow);
        color: var(--black);

        .value {
            color: var(--black);
        }
    }

    .container {
        @include flex(flex-start, space-between);
        flex-wrap: wrap;
        gap: 40px;

        @include responsive($tabletSmall) {
            padding: space(0.5) space(2);
            flex-wrap: nowrap;
            gap: space(1);
        }

        > * {
            width: 100%;

            &.content {
                @include responsive($tabletSmall) {
                    min-width: 50%;
                }
            }
        }
    }

    ul {
        @include flex(flex-start, space-between);
        gap: 30px;
        flex-wrap: wrap;

        @include responsive($tabletSmall) {
            gap: get-vw(60) get-vw(80);
        }

        li {
            min-width: 100%;
            
            @include responsive($tabletSmall) {
                flex: 1 1 0px;
                min-width: calc(50% - get-vw(60));
            }

            .value {
                display: block;
                font-weight: 900;
                font-size: 38px;
                line-height: 1;
                color: var(--yellow);

                @include responsive($tabletSmall) {
                    @include font-vw(38);
                }
            }

            .legend {
                font-size: 20px;
                line-height: 1.3;

                @include responsive($tabletSmall) {
                    @include font-vw(20);
                }
            }
        }
    }
}