.CharteEthiqueBox{
  background-color: var(--white);
  color: var(--black);
  padding: 2em;

  .wswyg--content{
    display: grid;
    grid-gap: 1em;
    font-weight: 500;

    *{
      margin: 0;
    }

    h2{
      line-height: 1;

      @include responsive($tabletSmall){
        @include font-vw(100);
      }
    }

    a{
      font-weight: 900;
    }
  }

  // Normal style
  &:not(.--quote) {
    
  }

  // Special styles for type "quote"
  &.--quote{
    .wswyg--content{
      color: var(--black);

      *{ color: inherit; }

      &:before,
      &:after{
        @include content();
        height: 18px;
        width: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.6257 0V3.67598C5.59218 4.02793 4.96648 4.81006 4.96648 6.72626H7.86034V14H0L0 7.93855C0 3.2067 2.62011 0.351955 7.6257 0ZM18.1061 0V3.67598C16.0726 4.02793 15.4469 4.81006 15.4469 6.72626H18.3408V14H10.4804V7.93855C10.4804 3.2067 13.1006 0.351955 18.1061 0Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100%;
        
        @include responsive($tabletSmall){
          height: get-vw(18);
          width: get-vw(14);
        }
      }

      &:after{
        transform: rotate(180deg);
      }
    }
  }
}
