.Ressources {
  &__page {
    .Page__header {
      text-align: center;
      background: var(--black);
      color: var(--white);
      padding-bottom: 80px;

      @include responsive($tabletSmall) {
        padding-bottom: get-vw(80);
      }

      h1 span {
        text-align: center;
      }
    }
  }

  &__grid {
    @include flex(stretch, flex-start);
    flex-wrap: wrap;
    gap: 40px;
    transition: opacity 420ms $ease;

    @include responsive($tabletSmall) {
      gap: space(1);
    }

    .--loading & {
      opacity: 0.2;
      pointer-events: none;
    }

    > * {
      width: 100%;
      
      @include responsive($mobile) {
        max-width: calc(50% - 20px);
      }

      @include responsive($tabletSmall) {
        max-width: calc(33.333% - space(1));
      }

      &.--featured {
        max-width: 100%;

        @include responsive($tabletSmall) {
          max-width: calc(66.666% - space(1));
        }
      }
    }
  }

  &__list {

    .--loading & {
      cursor: progress;
    }

    .Pagination {
      margin-top: 40px;
      min-width: 100%;

      @include responsive($tabletSmall) {
        margin-top: get-vw(60);
      }

      &:empty {
        display: none;
      }
    }
  }
}