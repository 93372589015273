.Steps {
    h2 {
        font-size: 12vw;

        @include responsive($tabletSmall) {
          @include font-vw(160);
        }

        .--oh {
            &:nth-child(1) {
                @include responsive($tabletSmall) {
                    span {
                        left: 24%;
                    }
                }
            }
            
            &:nth-child(2) {
                @include responsive($tabletSmall) {
                    span {
                        left: 6%;
                    }
                }
            }
        }
    }

    .content {
        @include flex(flex-start, space-between);
        flex-wrap: wrap;

        > * {
            width: 100%;

            @include responsive($tabletSmall) {
                max-width: 50%;
            }
        }

        .headline {
            margin-bottom: 40px;

            @include responsive($tabletSmall) {
                position: sticky;
                top: 120px;
                max-width: 40%;
                margin-bottom: 0;
            }
        }

        h3 {
            font-size: 8vw;
            margin-bottom: 40px;

            @include responsive($tabletSmall) {
                @include font-vw(38);
                margin-bottom: get-vw(40);
            }
        }
    }

    &__item {
        position: relative;
        min-width: 100%;

        &:not(:first-child) {
            margin-top: 40px;
            padding-top: 40px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(40);
                padding-top: get-vw(40);
            }

            &:before {
                @include content();
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                border-top: 1px solid var(--grey-l);
            }
        }

        span {
            font-weight: 900;
            font-size: 8vw;
            line-height: 1;
            margin-bottom: 20px;

            @include responsive($tabletSmall) {
                @include font-vw(38);
                margin-bottom: get-vw(20);
            }
        }
    }
}