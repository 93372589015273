.CharteEthiqueBubble{
  width: 100%;
  max-width: 350px;
  opacity: 0;
  transform: translate(0, 10%) scale(0.5);
  transition: opacity 700ms $ease,
              transform 700ms $ease;

  @include responsive($tabletSmall){
    max-width: get-vw(350);
    margin-left: auto;
    margin-right: -30%;
  }

  &.--visible{
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  > span{
    font: 400 18px / 1 'Maison Neue Txt', sans-serif;
    color: #8E8D94;
    padding-left: 2em;
    margin-bottom: 0.65em;

    @include responsive($tabletSmall){
      @include font-vw(20);
    }
  }

  > .wswyg--content{
    background-color: var(--grey-d);
    border-radius: 1em;
    padding: 1em 2em;
    position: relative; 


    .CharteEthiqueSection.--black &{
      background-color: var(--yellow);

      * {
        color: var(--grey-d);
      }

      &:before{
        background-image: url("data:image/svg+xml,%3Csvg width='33' height='28' viewBox='0 0 33 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.34058e-05 27.0926C16.2437 30.046 28.611 21.555 32.7642 16.9404L32.3027 3.09639C27.8419 4.01933 18.1818 4.84997 15.2284 0.789063C15.6899 17.4018 3.23032 25.7082 5.34058e-05 27.0926Z' fill='%23FFDA15'/%3E%3C/svg%3E%0A");
      }
    }

    &:before{
      @include content();
      position: absolute;
      bottom: 0; right: 100%;
      transform: translate(55%, 0);
      height: get-vw(28);
      width: get-vw(33);
      background-image: url("data:image/svg+xml,%3Csvg width='33' height='28' viewBox='0 0 33 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.34058e-05 27.0926C16.2437 30.046 28.611 21.555 32.7642 16.9404L32.3027 3.09639C27.8419 4.01933 18.1818 4.84997 15.2284 0.789063C15.6899 17.4018 3.23032 25.7082 5.34058e-05 27.0926Z' fill='%23282828'/%3E%3C/svg%3E%0A");
      background-size: 100%;
      background-repeat: no-repeat;

      @include responsive($tabletSmall, max){
        min-height: 14px;
        min-width: 33px * 0.5;
      }
    }

    *{
      font-family: 'Maison Neue Txt', sans-serif;
      color: var(--white);
      line-height: 1.3;
    }

    p, ul, ol{
      font-weight: 500;

      @include responsive($tabletSmall){
        @include font-vw(27);
      } 
    }
  }
}
