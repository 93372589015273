.testimony {
    background: var(--grey-d);
    padding-top: 50px;
    padding-bottom: 50px;
    color: #F2F2F2;
    padding: 0;

    .container {
        padding: 0;
        @include flex(stretch, space-between);
        flex-wrap: wrap;

        > * {
            width: 100%;
            @include responsive($tabletSmall) {
                flex: 1 1 0px;
            }
        }
    }

    &__card {
        padding: 25px;
        @include flex(flex-start, flex-start);
        gap: 50px;
        flex-direction: column;

        @include responsive($tabletSmall) {
            padding: space(0.5);
            gap: get-vw(50);
        }

        > * {
            width: 100%;
        }

        &.--full {
            @include responsive($tabletSmall) {
                flex-direction: row;
                align-items: stretch;
            }

            .testimony__image {
                .visual__container {
                    @include imgRatio(720, 640, var(--black));

                    @include responsive($tabletSmall) {
                        height: 100%;
                        background: var(--black);
                    }

                    &:before {
                        @include responsive($tabletSmall) {
                            display: none;
                        }
                    }
                }
            }

            .testimony__text .quote {
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(80);
                }
            }
        }
    }

    &__image {
        .visual__container {
            @include imgRatio(720, 420, var(--black));
        }
    }

    &__text {
        @include flex(flex-start, flex-start);
        gap: 40px;
        flex-direction: column;
        flex-grow: 1;

        @include responsive($tabletSmall) {
          gap: get-vw(40);
        }

        .icon {
            position: relative;
            @include imgRatio(96, 74);
            width: 64px;
            margin-bottom: -20px;

            @include responsive($tabletSmall) {
                width: get-vw(64);
                margin-bottom: 0;
            }

            svg {
                @include cover();
            }
        }

        .author {
            margin-top: auto;
            color: var(--font-color-grey);
            letter-spacing: -0.16px;
            font-size: 16px;

            @include responsive($tabletSmall) {
                @include font-vw(16);
            }

            span {
                display: block;
            }

            .name {
                color: var(--yellow);
                font-size: 24px;
                font-weight: 900;
                letter-spacing: -0.24px;
                text-transform: uppercase;
                line-height: 1.2;

                @include responsive($tabletSmall) {
                    @include font-vw(24);
                }
            }

            .job {
                font-weight: 600;
            }

            .filmographie {
                font-weight: 600;
                margin-top: 16px;
                padding-left: 20px;

                @include responsive($tabletSmall) {
                    padding-left: get-vw(20);
                    margin-top: get-vw(16);
                }

                ul {
                    list-style-type: disc;
                    line-height: 1.2;
                }
            }
        }
    }
}