.ImageSection {
    padding-top: 0;
    
    .visual {
        position: relative;
    }

    &.--prllx {
        background: var(--black);
        padding: 0;

        .visual__container {
            @include imgRatio(1600, 840);

            .visual {
                @include cover();
                top: -10%;
                bottom: -10%;
                height: auto;
            }
        }
    }
}