$tarteaucitron-bgcolor: var(--base);
$allowed-color: #20b55f;
$denied-color: #d44545;

#tarteaucitronRoot {
  z-index: 1000;

  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      @include responsive(520px) {
        display: flex!important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;

        @include responsive($tabletSmall) {
          flex-wrap: nowrap;
        }
      }
    }

    &:before {
      @include content();
      @include cover();
      position: fixed;
      height: 100vh;
      background: var(--black);
      opacity: 0.6;
      z-index: 10;
    }
  }

  #tarteaucitronBack {
    background: var(--black)!important;
    opacity: 0.9!important;
  }

  #tarteaucitronAlertBig {
    background: var(--yellow)!important;
    padding: 20px!important;
    box-sizing: border-box!important;
    left: 8px!important;
    right: 8px!important;
    bottom: 8px!important;
    width: auto!important;
    margin: 0!important;

    @include responsive($tabletSmall) {
      max-width: 840px;
      left: 50%!important;
      right: initial!important;
      bottom: 20px!important;
      margin: 0;
      transform: translateX(-50%);
    }

    #tarteaucitronDisclaimerAlert {
      margin-right: auto!important;
      font-size: 12px;
      display: block;
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      color: var(--black);
      padding: 0;
      margin: 0 10px!important;
      font-family: var(--main-font);
      font-weight: 500;

      @include responsive($tabletSmall) {
        text-align: left;
        min-width: 280px;
      }

      strong {
        font-size: 12px;
        color: var(--black);
      }
    }

    > button {
      margin: 0!important;
      background: transparent!important;
      font-size: 12px!important;
      font-weight: bold;
      border: 1.5px solid var(--black) !important;
      margin-top: 10px!important;
      padding: 16px 20px!important;
      color: var(--black) !important;
      width: auto;
      max-width: 100%;
      box-sizing: border-box;

      @include responsive($tabletSmall) {
        margin-top: 0!important;
        white-space: nowrap;
      }

      &:hover {
        background: var(--yellow) !important;
        color: $tarteaucitron-bgcolor!important;
      }

      &#tarteaucitronCloseAlert {
        background: var(--black) !important;
        color: var(--white) !important;
      }

      span {
        display:none!important;
      }
    }
  }

  #tarteaucitronClosePanel {
    background: $tarteaucitron-bgcolor!important;
    border-color: $tarteaucitron-bgcolor!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $tarteaucitron-bgcolor!important;
      border-color: $tarteaucitron-bgcolor!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: var(--white) !important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $tarteaucitron-bgcolor!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $allowed-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $denied-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}
