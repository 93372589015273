.App {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 10;
  color: var(--grey-d);
  background: var(--white);

  html.--dark & {
    background: var(--base);
    color: var(--white);
  }

  > * {
    width: 100%;

    html:not(.no-js) body.--loading & {
      pointer-events: none;
    }

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 30px;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 space(1);
  }
}

section {
  padding: 60px 0;

  @include responsive($tabletSmall) {
    padding: space(1.5) 0;
  }

  &.--yellow {
    background: var(--yellow);
  }
}
