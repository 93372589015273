.Filters {
  margin-top: 10px;

  ul {
    @include flex(center, center);
    flex-wrap: wrap;
    gap: 6px 24px;

    a {
      position: relative;
      color: var(--white);
      font-weight: 900;
      opacity: 0.3;
      transition: opacity 420ms $ease;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 14px;

      @include responsive($tabletSmall) {
        @include font-vw(14);
      }

      @include noTouchHover() {
        opacity: 1;
        &:after {
          transform: scaleX(1);
        }
      }

      &.--active {
        opacity: 1;
        &:after {
          transform: scaleX(1);
        }
      }

      &:after {
        @include content();
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 2px solid var(--white);
        transform: scaleX(0);
        transition: transform 420ms $ease;
      }
    }
  }
}