.CharteEthiqueSection {
  background-color: var(--black);
  padding: 5em 0;

  @include responsive($tabletSmall) {
    padding: space(2) 0;
  }

  // Base styles (black version)
  h2.--splited{
    color: var(--white);

    @include responsive($tabletSmall){
      @include font-vw(140);
      margin-bottom: 0.25em;
    }

    .--oh{
      position: relative;
    }

  }

  .content{
    display: grid;
    grid-gap: 1.5em;
    font-weight: 500;
    color: #9C9C9C;
    
    @include responsive($tabletSmall){
      @include font-vw(20);
      max-width: get-vw(830);
      margin: 0 auto;
    }

    p,
    ul,
    ol, 
    li{
      font-size: inherit;
    }

    ol,
    ul{
      margin-top: -1em;
      padding-left: 1em;
    }

    ul{
      list-style: disc;
    }

    ol{
      list-style: decimal;
    }

    @for $i from 1 through 15 {
      > *:nth-child(#{$i}) {
        order: #{$i};
      }
    }
  }

  // Different styles for each themes
  &.--yellow,
  &.--white{
    h2{
      color: var(--dark);
    }
  }

  &.--yellow{
    background-color: var(--yellow);

    .content{
      > *:not(.CharteEthiqueBox){ color: var(--grey-d); }
    }
  }

  &.--white{
    background-color: var(--grey-l);

    .content{
      > *:not(.CharteEthiqueBox){ color: var(--text); }
    }
  }

  // Different styles for each section (agencement)
  &:nth-child(1) {
    .--splited{
      .--oh{
        &:nth-child(1){
          @include responsive($tabletSmall){
            left: 10%; 
          }
        }
        &:nth-child(2){
          @include responsive($tabletSmall){
            left: 50%;
          }
        }
      }
    }

    .CharteEthiqueBox.--quote{
      order: 3;
    }

    .CharteEthiqueBubble{
      order: 1;

      @include responsive($tabletSmall){
        margin-left: -30%;
        margin-right: auto;
      }
    }
  }

  &:nth-child(2) {
    .--splited{
      .--oh{
        &:nth-child(1){
          @include responsive($tabletSmall){
            left: 22%; 
          }
        }
        &:nth-child(3){
          @include responsive($tabletSmall){
            left: 12%;
          }
        }
      }
    }

    .CharteEthiqueBox{
      &:nth-of-type(1) {
        background-color: var(--grey-d);
        order: 1;

        .wswyg--content {
          text-align: center;
          
          * { color: var(--white); }
        }
      }

      &:nth-of-type(2) {
        order: 3;
      }
    }
  }

  &:nth-child(3) {
    .CharteEthiqueBox{
      &:nth-of-type(1) {
        order: 2;
      }
    }
  }

  &:nth-child(4) {
    .--splited{
      .--oh{
        &:nth-child(1){
          @include responsive($tabletSmall){
            left: 3.5%; 
          }
        }
        &:nth-child(2){
          @include responsive($tabletSmall){
            left: 15%;
          }
        }
      }
    }

    .CharteEthiqueBox{
      &:nth-of-type(1) {
        background-color: var(--yellow);

        .wswyg--content {
          text-align: center;
          
          * { color: var(--black); }
        }
      }
    }
  }
  
  &:nth-child(5) {}
  
  &:nth-child(6) {
    .CharteEthiqueBox{
      &:nth-of-type(1) {
        background-color: var(--grey-d);
        order: 1;

        .wswyg--content {
          text-align: center;
          
          * { color: var(--white); }
        }
      }
    }
  }
  
  &:nth-child(7) {
    .--splited{
      .--oh{
        &:nth-child(1){
          @include responsive($tabletSmall){
            left: 30%; 
          }
        }
        &:nth-child(3){
          @include responsive($tabletSmall){
            left: 15%;
          }
        }
      }
    }

    .CharteEthiqueBox{
      &:nth-of-type(1) {
        order: 1;

        .wswyg--content {
          text-align: center;
        }
      }

      &:nth-of-type(2) {
        background-color: var(--yellow);

        .wswyg--content {
          * { color: var(--grey-d); }
        }
      }
    }
  }
}