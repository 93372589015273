.projects {
    &__title {
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(80);
            @include flex(flex-end,space-between);
            gap: 20px;
        }

        h2 {
            flex-basis: 55%;
            font-size: 10vw;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 0;
            position: relative;

            @include responsive($tabletSmall, max) {
                margin-bottom: 20px;
            }

            @include responsive($tabletSmall) {
              @include font-vw(80);
            }

            .--oh {
                position: relative;
                &:nth-child(2) {
                    @include responsive($tabletSmall) {
                      transform: translateX(16%);
                    }
                }
            }

            span {
                display: block;
                position: relative;
        
                &:nth-child(2) {
                  @include responsive($tabletSmall) {
                      left: 15%
                  }
                }
            }
        }

        p {
            flex-basis: 32%;
            font-family: 'Maison Neue Txt', sans-serif;
        }
    }
}