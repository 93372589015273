.l24 {
    @include flex(flex-start, flex-start);
    gap: 14px;

    @include responsive($tabletSmall) {
        gap: get-vw(14);
    }

    img, svg {
        @include cover();
        object-fit: cover;

        path {
            fill: var(--base);
        }
    }

    &__baseline {
        position: relative;
        @include imgRatio(90, 30);
        width: 90px;

        @include responsive($tabletSmall) {
            width: get-vw(90);
        }

        svg {
            @include cover();
        }
    }

    &__numbers {
        position: relative;
        display: block;
        overflow: hidden;
        width: 70px;
        @include imgRatio(100, 85);

        @include responsive($tabletSmall) {
            width: get-vw(100);
        }

        span {
            // padding: 5px 0;
            @include imgRatio(91, 46);
            width: 100%;
            top: 0;
            left: 0;
            padding: 4.6% 0;
            //left: 50%;
            //top: 50%;
            position: absolute;
            display: block;

            /* &:nth-child(1) {
                transform: translate(-50%, -170%);
            }

            &:nth-child(3) {
                transform: translate(-50%, 70%);
            }

            .--loading &, .--intro & {
                transition: none;
                animation : logoAnimation2 1000ms infinite steps(24) forwards;
                
                &:nth-child(1) {
                    animation : logoAnimation1 1000ms infinite steps(24) forwards;
                }
    
                &:nth-child(3) {
                    animation : logoAnimation3 1000ms infinite steps(24) forwards;
                }
            }*/

            svg {
                transform: translate(0%, 20%);
            }
        }
    }

    .Footer & {
        gap : 34px;

        @include responsive($tabletSmall) {
            gap: get-vw(34);
        }

        &__baseline {
            width : 320px;
    
            @include responsive($tabletSmall) {
                width: get-vw(320);
            }
        }

        &__numbers {
            width : 360px;
    
            @include responsive($tabletSmall) {
                width: get-vw(355);
            }
        }
    }
}


@keyframes logoAnimation1 {

    0% {
        opacity: 1;
        transform: translate(-50%, -170%);
    }

    1% {
        opacity: 0;
        transform: translate(-50%, -170%);
    }

    32% {
        opacity: 0;
        transform: translate(-50%, 70%);
    }

    33% {
        opacity: 1;
        transform: translate(-50%, 70%);
    }

    66% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-50%, -170%);
    }
}

@keyframes logoAnimation2 {
    0% {
        transform: translate(-50%, -50%);
    }

    33% {
        opacity: 1;
        transform: translate(-50%, -170%);
    }

    34% {
        opacity: 0;
        transform: translate(-50%, -170%);
    }


    65% {
        opacity: 0;
        transform: translate(-50%, 70%);
    }

    66% {
        opacity: 1;
        transform: translate(-50%, 70%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes logoAnimation3 {

    0% {
        transform: translate(-50%, 70%);
    }

    33% {
        transform: translate(-50%, -50%);
    }

    66% {
        opacity: 1;
        transform: translate(-50%, -170%);
    }

    67% {
        opacity: 0;
        transform: translate(-50%, -170%);
    }

    99% {
        opacity: 0;
        transform: translate(-50%, 70%);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 70%);
    }
}